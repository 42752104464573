.display-person-info{
  display: none;
}
.text-padding{
  padding-top: 10px;
}

@media screen and (max-width: 430px) {
  .container {
    max-width: 430px;
  }
  .display-person-info{
    display: inline-block;
  }
  .mobile-display-hide{
    display: none;
  }
}