@import "../../scss/theme-colors.module";

.bg-flower-form {
  background: #fefaf9;
}

.flower-form-wrapper {
  min-height: 550px;
  align-content: center;
}

.form-back-button {
  text-decoration: underline;
  margin-bottom: 2em;
  font-size: 13px;
  color: $primary;
  img {
    width: 20px;
  }
}

.flower-form {
  label {
    font-size: 13px;
  }
  .form-control {
    font-size: 13px;
    background: $form-bg-color;
    font-family: 'DomaineText';
    &:focus {
      box-shadow: none;
      background: $form-bg-color;
      font-family: 'DomaineText';
    }
  }
  //.flower-form-select {
  //  &:hover {
  //    border-color: black;
  //  }
  //  &:active {
  //    border-color: gray;
  //  }
  //  border-color: black;
  //  border-radius: 0;
  //  background: $form-bg-color;
  //  font-size: 13px;
  //  font-family: 'DomaineText'
  //}
}
