/**
* Image styling
*/

/* Container to center the image in the middle of the screen */
.status-image-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Ensures both vertical and horizontal centering */
    z-index: 1000; /* Ensure it's above other content */
    animation: fadeInOut 15s forwards; /* Animation for showing and hiding */
    text-align: center; /* Ensures proper alignment if needed */
    display: flex; /* Flexbox for centering */
    justify-content: center; /* Horizontally center content */
    align-items: center; /* Vertically center content */
  }
  
  /* The image itself */
  .status-image {
    width: 150px; /* Adjust size as needed */
    animation: moveImage 3s infinite; /* Movement animation */
  }
  
  /* Keyframes for fading in and out */
  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  /* Keyframes for moving the image */
  @keyframes moveImage {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  