@import "scss/theme-colors.module";
@import "./scss/font-styles.scss";

$font-family-base: "Matter";

@import "~bootstrap/scss/bootstrap";
//@import 'bootstrap/dist/css/bootstrap.css';

//@media (min-width:1400px) and (min-height:1024px) {

//FONT STYLE TO THE PERSON LIST
.font-style {
  font-family: DomaineText;
}

.font-domain {
  font-family: DomaineText;
}

p {
  font-size: 13px;
}

.font-w-bold {
  font-weight: bold;
}

//SEARCH BOX
.search_box_hidden {
  background-color: lightgray;
  height: 2.7rem;
  border-radius: 30px;
}

.font-w-600 {
  font-weight: 600;
}

.search_box {
  display: flex;
  align-items: center;

  > input {
    flex: 1;
    border: none;
    outline: none;
    font-size: 14px;
  }
}

//LOGO
.navbar-brand {
  font-size: 30px;
}

//BUTTON
.btn-secondary {
  --bs-btn-bg: #E7D2A8;
  --bs-btn-border-color: #E7D2A8;
  --bs-btn-hover-bg: #E7D2A8;
  --bs-btn-hover-border-color: #E7D2A8;
  --bs-btn-active-bg: #E7D2A8;
  --bs-btn-active-border-color: #E7D2A8;
  --bs-btn-disabled-bg: #E7D2A8;
  --bs-btn-disabled-border-color: #E7D2A8;
  color: white;
}

.btn {
  --bs-btn-border-radius: 0;
  --bs-btn-font-size: 1.25rem;
}

.btn-condoleren {
  width: 100%;
}

//DROPDOWN
.dropdown-menu {
  --bs-dropdown-min-width: 2rem;
}

//FORM
.form-control {
  border: 1px solid black;
  border-radius: 0;
}

.form-check-input[type=checkbox] {
  border-radius: 0;
}

.form-check-input {
  border: 1px solid black;
}

.active-page {
  color: unset !important;
}

.page-option {
  color: #C4A484;
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}
.anchor-no-decoration {
  text-decoration: none;
  color: unset;
}

//}
@media screen and  (max-width: 430px) {
  .btn {
    --bs-btn-border-radius: 0;
    --bs-btn-font-size: 1.25rem;
    //position: fixed;
  }
  .container{
    max-width: 430px;
  }
  .mobile-footer{
    display: none;
  }
  .hide-logo ,.remove-lng-dp{
    display: none;
  }
}



