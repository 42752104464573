@import "../../scss/theme-colors.module";
.medium-text {
  font-size: medium;
}

.form-control {
  background: $form-bg-color;
  font-family: 'DomaineText';
  &:focus {
    background: $form-bg-color;
    font-family: 'DomaineText';
  }
}
.form-check-input {
  --bs-form-check-bg: $form-bg-color;
}
