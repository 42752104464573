.how-it-works {
    h6 {
        font-weight: bold;
    }
}

.blend-mode-darken {
    mix-blend-mode: darken;
}

.flower-header-image {
    width: 420px;
    @media screen and (max-width: 430px) {
        width: 100%;
        justify-self: center;
    }
}