@import "../../scss/theme-colors.module";

.searchbox-wrapper {

  .searchbox {
    position: relative;

    & .input {
      width: 100%;
      border: 0;
      height: 2.7rem;
      padding: 0 50px 0 10%;
      border-radius: 50px;
      background: $light-gray;
      //box-shadow: 0 0 15px 1px #b6bbc0;
      outline: none;
    }

    .searchbtn {
      position: absolute;
      top: 0;
      height: 100% !important;
      right: 0px;
    }
  }
}

.react-select__clear-indicator {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.react-select__value-container {
  padding: 0 !important;
}
.react-select__control {
  min-height: unset !important;
}

.react-select__input-container {
  margin: 0 !important;
  padding: 0 !important;
}

@media screen and (max-width: 767px) {
  .container {
    max-width: 430px;
  }
  .mobile-display-hide{
    display: none;
  }
  .hide-border {
    border:none !important;
  }
}
