.invitationModalNoBorderRadius {
  border-radius: unset;
  .modal-dialog {
    .modal-content {
      border-radius: unset;
      .modal-header {
        border-radius: unset;
      }
    }
  }
}