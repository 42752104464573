@import "../../scss/theme-colors.module";

.bg-crowdfunding-form {
  background: #fefaf9;
}

.bg-crowdfunding-success {
    background: #f3fff2;
}

.bg-crowdfunding-failed {
    background: #ffeded;
}

.crowdfunding-form-wrapper {
  min-height: 400px;
  align-content: center;
}

.form-back-button {
  text-decoration: underline;
  margin-bottom: 2em;
  font-size: 13px;
  color: $primary;
  img {
    width: 20px;
  }
}

.crowdfunding-form {
  label {
    font-size: 13px;
  }
  .form-control {
    font-size: 13px;
    background: $form-bg-color;
    font-family: 'DomaineText';
    &:focus {
      box-shadow: none;
      background: $form-bg-color;
      font-family: 'DomaineText';
    }
  }
}