@import "../../scss/theme-colors.module";

.person-img {
  width: 100%;
  border-radius: 50%;
  cursor: pointer;
  background: lightgray;
  height: auto;
  max-height: 55px;
  max-width: 55px;
}

.person-name {
  font-size: 20px;
}

.dateNoWrap {
  white-space: nowrap;
}

.page-amount {
  background: $light-gray;
  width: 50px;
  text-align: center;
}

@media  screen and (max-width: 430px)  {
  .container {
    max-width: 430px;
  }
  .mobile-bottom-alignment{
    position: fixed;
    bottom: 0;
    left: 0;
  }
}

@media screen and (max-width: 767px) {
  .pagination-col {
    &:first-child {
      order: 0;
    }
    &:last-child {
      order: 1;
    }
    &:nth-child(2) {
      order: 2;
    }
  }
  .mobile-display-hide{
    display: none;
  }
}
