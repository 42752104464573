.card-carousel {
  position: relative;
  .carousel {
    .carousel-control-next {
      z-index: 10;
    }.carousel-control-prev {
      z-index: 10;
    }
    .carousel-indicators {
      z-index: 6;
      width: fit-content;
      justify-self: center;
      & [data-bs-target] {
        opacity: 1 !important;
        background-color: white;
      }
      .active {
        background-color: black !important;
      }
      button {
        width: 10px !important;
        height: 10px !important;
        border: 1px solid transparent !important;
        border-radius: 10px;
      }
    }
  }
  .card-shop-overlay {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 5;
    cursor: pointer;
  }
}

