.card-condolences {
  background-color: #F3E8D3;
}
.card-flowers {
  background-color: #E3E8E3;
}
.card-communication {
  background-color: #F3EEFF;
}
.card-meal {
  background-color: #F4EDEE;
}
.card-condolences-card {
  background-color: #DEE2E6;
}
.card-reminder {
  background-color: #F5FBFD;
}
.card-coffee_table {
  background-color: #F3E8D3;
}
.card-invitation {
  background-color: #F3EEFF;
}
.card-redirect {
  background-color: #E3E8E2;
}
.card-financial-support {
  background-color: #FEFEFD;
}
.card-mourning-list {
  background-color: #F6F5F0;
}
.card-share-image {
  background-color: #F8FFF1;
}