@import "../../scss/theme-colors.module";

.bg-participation-card-form {
  background: #fefaf9;
}

.participation-card-form-wrapper {
  min-height: 470px;
  align-content: center;
}

.participation-card-form {
  label {
    font-size: 13px;
  }

  .form-control {
    font-size: 13px;
    background: $form-bg-color;
    font-family: 'DomaineText';

    &:focus {
      box-shadow: none;
      background: $form-bg-color;
      font-family: 'DomaineText';
    }
  }
}