//DomainText

@font-face {
  font-family: 'DomaineText';
  src: url("../assets/font/DomaineText-Black.otf") format("truetype");
  font-weight: 900; //in google fonts its mentioned 900
  font-style: (Black);
}
@font-face {
  font-family: 'DomaineText';
  src: url("../assets/font/DomaineText-BlackItalic.otf") format("truetype");
  font-weight: 900; //in google fonts its mentioned 900
  font-style: (Regular);
}
@font-face {
  font-family: 'DomaineText';
  src: url("../assets/font/DomaineText-Bold.otf") format("truetype");
  font-weight: 700; //in google fonts its mentioned 700
  font-style: (Bold);
}
@font-face {
  font-family: 'DomaineText';
  src: url("../assets/font/DomaineText-BoldItalic.otf") format("truetype");
  font-weight: 700; //in google fonts its mentioned 700
  font-style: (Regular);
}
@font-face {
  font-family: 'DomaineText';
  src: url("../assets/font/DomaineText-Light.otf") format("truetype");
  font-weight: 300; //in google fonts its mentioned 300
  font-style: (Light);
}
@font-face {
  font-family: 'DomaineText';
  src: url("../assets/font/DomaineText-LightItalic.otf") format("truetype");
  font-weight: 300; //in google fonts its mentioned 300
  font-style: (Regular);
}
@font-face {
  font-family: 'DomaineText';
  src: url("../assets/font/DomaineText-Medium.otf") format("truetype");
  font-weight: 500; //in google fonts its mentioned 500
  font-style: (Medium);
}
@font-face {
  font-family: 'DomaineText';
  src: url("../assets/font/DomaineText-MediumItalic.otf") format("truetype");
  font-weight: 500; //in google fonts its mentioned 500
  font-style: (Regular);
}
@font-face {
  font-family: 'DomaineText';
  src: url("../assets/font/DomaineText-Regular.otf") format("truetype");
  font-weight: 400; //in google fonts its mentioned 400
  font-style: (Regular);
}
@font-face {
  font-family: 'DomaineText';
  src: url("../assets/font/DomaineText-RegularItalic.otf") format("truetype");
  font-weight: 400; //in google fonts its mentioned 400
  font-style: (Regular);
}

//Matter Note: Didn't find font-weight for Matter, so kept same as DomainText

@font-face {
  font-family: 'Matter';
  src: url("../assets/font/Matter-Bold.otf") format("truetype");
  font-weight: 700;
  font-style: (Bold);
}
@font-face {
  font-family: 'Matter';
  src: url("../assets/font/Matter-Regular.otf") format("truetype");
  font-weight: 400;
  font-style: (Regular);
}
@font-face {
  font-family: 'Matter';
  src: url("../assets/font/Matter-Light.otf") format("truetype");
  font-weight: 300;
  font-style: (Light);
}

//silver line-script

@font-face {
  font-family: 'silverline';
  src: url("../assets/font/silverline_script.otf") format("truetype");
  font-weight: 400;
  font-style: (regular);   //need to check
}
