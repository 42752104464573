$card-price-height: 38px;
.card-container {
  position: relative;
  .card-image {
    width: 75%;
    &:hover {
      cursor: pointer;
    }
  }
  .card-price {
    &:hover {
      cursor: pointer;
    }
    position: absolute;
    top: 75%;
    left: 70%;
    display: inline-block;
    height: $card-price-height;
    align-content: center;
    margin: 0 16px 8px 0;
    padding: 0 10px 0 12px;
    background: black;
    -webkit-border-bottom-right-radius: 5px;
    border-bottom-right-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    border-top-right-radius: 5px;
    color: #fff;
    text-decoration: none;
    font-weight: bold;

    &:before {
      content: "";
      position: absolute;
      top:0;
      left: calc(-1 * $card-price-height / 2) + 5;
      width: 0;
      height: 0;
      border-color: transparent black transparent transparent;
      border-style: solid;
      border-width: calc($card-price-height / 2) (calc($card-price-height / 2)) - 5 calc($card-price-height / 2) 0;
    }

    &:after {
      content: "";
      position: absolute;
      top: 16px;
      left: 0;
      width: 7px;
      height: 7px;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      background: #fff;
      -webkit-box-shadow: -1px -1px 2px rgba(0,0,0,0.4);
      box-shadow: -1px -1px 2px rgba(0,0,0,0.4);
    }
  }
}

.participation-card-header-title {
  max-width: 710px;
}