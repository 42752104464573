.pagination-container {
  display: flex;
  list-style-type: none;
  justify-content: center;
  padding-left: 0;
  @media screen and (max-width: 430px) {
    margin-bottom: 0;
  }

  .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;
    @media screen and (max-width: 430px){
      font-size: small;
      min-width: auto;
      height: 100%;
      padding: 0 4px;
    }

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }

    &.selected {
      //background-color: rgba(0, 0, 0, 0.08);
      font-weight: bold;
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}