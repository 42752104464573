.sh-mourn-visit-btn ,.sh-display{
  display: none;
}
.funeral_home {
  .anchor-no-decoration {
    text-decoration: none;
    color: unset;
  }
}

.sh-person-info {
  font-size: 13px;
  line-height: unset;
  @media screen and (max-width: 767px) {
    margin-bottom: 2em;
  }
}

.my-sereni-text{
  @media screen and (max-width: 767px) {
    margin-top: 0 !important;
    margin-bottom: 3em !important;
  }
  font-size: 13px;
  strong {
    font-weight: unset;
  }
}

.person-detail-name {
  @media screen and (max-width: 767px) {
    margin-bottom: 0.5em !important;
  }
}

.livestream-button {
  background-color: black;
  color: white;
  border: 0 solid transparent;
}

.person-detail-info-col {
  @media screen and (max-width: 767px) {
    margin-left: 0 !important;
    display: flex;
    flex-direction: column;
    .mourning-accordion {
      width: 50%;
      margin: 3em  auto 0 auto !important;
      order: 3;
    }
  }

}

.image-shape {
  border-radius: 50%;
  width: 100%;
  max-height: 260px;
  max-width: 260px;
  @media screen and (max-width: 767px){
    width: 50%;
    max-height: 180px;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 430px) {
  .container {
    max-width: 430px;
  }
  .align-middle{
    vertical-align: middle !important;
    text-align: center;
  }
  .mobile-display-hide , .sh-person-info{
    display: none;
  }
  h1{
    margin-top: 10px !important;
    margin-bottom: 0;
  }
  .remove-margin{
    margin-top: 0 !important;
    margin-bottom: 1em;
  }
.sh-display{
  display: inline-block;
  position: relative;
}
  .sh-mourn-visit-btn{
    display: inline-block;
    background-color:white ;
    border:none;
    padding-bottom: 0;
    padding-top: 30px ;
  }
}
